<template>
	<div>
		<CustomTable
			:recent-filter="recentFilters"
			:total-pages="totalPages"
			:total-items="totalItems"
			:label-search="$t('PaysheetFilter.search')"
			:label-date="$t('PaysheetFilter.createdDate')"
			:show-create-button="true"
			:text-create-button="$t('PaysheetButtons.create')"
			@create="create"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
					>
						{{ header.title }}
					</th>
				</tr>
			</template>
			<template #body>
				<template v-if="paysheets && paysheets.length">
					<tr v-for="(item, index2) in paysheets" :key="index2">
						<td scope="row" class="text-break text-pre-wrap text-center paysheet-name">
							{{ item.name }}
						</td>
						<td scope="row" class="text-break text-pre-wrap text-center paysheet-date">
							{{ convertDateToDateVN(item.createdDate) }}
						</td>
						<td scope="row" class="text-center actions fit">
							<router-link
								:to="{
									name: 'UpdatePaysheet',
									params: { id: item.id },
								}"
								class="link-icon"
							>
								<span class="prefix-input d-inline-block mr-3">
									<font-awesome-icon :icon="['fas', 'pencil-alt']" :title="$t('Action.Edit')" />
								</span>
							</router-link>
							<span class="prefix-input d-inline-block trash" @click="showConfirmDelete(item.id)">
								<font-awesome-icon :icon="['fas', 'trash-alt']" :title="$t('Action.Delete')" />
							</span>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="11" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTable>
		<CustomModal
			v-if="isShowConfirmDelete"
			:title="null"
			:submit-text="$t('PaysheetButtons.delete')"
			@submit-modal="confirmDelete"
			@cancel-modal="cancelDelete"
		>
			<template #content>
				{{ $t("PaysheetMessages.delete.body") }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { GET_PAYSHEETS, DELETE_PAYSHEET } from "../store/action-types"
const { mapState, mapActions } = createNamespacedHelpers("paysheet")
export default {
	name: "PaysheetManagement",
	data() {
		return {
			headers: [
				{
					title: this.$t("PaysheetFieldTable.Name"),
					class: "text-nowrap text-center paysheet-name",
				},
				{
					title: this.$t("PaysheetFieldTable.CreatedDate"),
					class: "text-nowrap text-center paysheet-date",
				},
				{
					title: this.$t("PaysheetFieldTable.Actions"),
					class: "text-center paysheet-actions fit",
				},
			],
			isShowConfirmDelete: false,
			paysheetID: null,
		}
	},
	computed: {
		...mapState(["totalItems", "totalPages", "recentFilters", "paysheets"]),
	},
	methods: {
		...mapActions({ GET_PAYSHEETS, DELETE_PAYSHEET }),
		async search(data) {
			const params = {
				data,
				notLoading: false,
			}
			await this.GET_PAYSHEETS(params)
		},
		create() {
			this.$router.push({ name: "CreatePaysheet" })
		},
		showConfirmDelete(id) {
			this.paysheetID = id
			this.isShowConfirmDelete = true
			this.$store.commit("set", ["modalShow", true])
		},
		confirmDelete() {
			const params = {
				id: this.paysheetID,
			}
			this.DELETE_PAYSHEET(params)
			this.cancelDelete()
		},
		cancelDelete() {
			this.paysheetID = null
			this.isShowConfirmDelete = false
			this.$store.commit("set", ["modalShow", false])
		},
	},
}
</script>
<style lang="scss" scoped>
.paysheet-actions {
	width: 4%;
}
</style>
